import styled from '@emotion/styled';
import React from 'react';
import Title from '~/components/LogoTitle';
import SEO from '~/components/Seo';
import { Section } from '~/utils/styles';

const Content = styled.div`
    text-align: center;
`;

const Text = styled.p`
    margin-top: 20px;
`;

const NotFoundPage = () => (
    <>
        <SEO title="404: Not found" />

        <Section>
            <Content>
                <Title level="h1">NOT FOUND</Title>
                <Text>You just hit a route that doesn&#39;t exist... the sadness.</Text>
            </Content>
        </Section>
    </>
);

export default NotFoundPage;
